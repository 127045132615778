import React from "react"
import DefinitionsCarousel from "./DefinitionsCarousel"
import DefinitionsCarouselL from "./DefinitionsCarouselL"
import DefinitionsCarouselR from "./DefinitionsCarouselR"

export default function DefinitionsCarousel3() {
    return (
        <>
        <DefinitionsCarouselL />
        <DefinitionsCarousel />
        <DefinitionsCarouselR />
        </>
    )
}
